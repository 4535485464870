import React from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../components";
import locales from "../../constants";

const MateuszGrzesiak = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  return (
    <Layout
      seo={{
        title: "Mateusz Grzesiak",
        href: slug,
        lang: "pl",
      }}
      header={{
        background:
          "linear-gradient( 45deg, #AE1435, #BC1236, #AE1435, #BC1236, #AE1435 )",
        icons: "#BC1236",
        navClass: "grzesiak",
        ogImage: require("../../assets/img/portfolio/grzesiak_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/projects/mateusz-grzesiak/",
      }}
    >
      <PortfolioHeader name="grzesiak" height="108" />
      <section className="container-fluid grzesiak_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Project Success Mateusz Grzesiak</h1>
              <ul>
                <li>Strona internetowa</li>
                <li>Kampania leadowa</li>
                <li>Banery reklamowe</li>
              </ul>
            </div>
          </div>

          <div className="col-md-6">
            <div className="inner">
              <p>
                Mateusz Grzesiak to jeden z najbardziej cenionych i
                rozpoznawalnych coachów na świecie. Projekt zakładał stworzenie
                strony internetowej pierwszej w Krakowie konferencji z udziałem
                Mateusza Grzesiaka. Strona została wykonana w technologii
                responsive web design oraz została zintegrowana z systemem
                sprzedaży biletów.
              </p>
              <p>
                Działaliśmy wspólnie przez kilka lat w trakcie których
                tworzyliśmy strony konferencyjne dla każdego projektu.
              </p>
              <p>Prowadziliśmy również kampanię sprzedażową.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid grzesiak_section_3">
        <div className="row row_1">
          <div className="col-md-6 col_1">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/grzesiak_main_1.png")}
              alt=""
            />
          </div>
          <div className="col-md-6 text-right">
            <div className="scroll_wrapper">
              <span />
              <div className="img_wrapper">
                <img
                  src={require("../../assets/img/portfolio/grzesiak_entire_page.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row no-gutters align-items-center row_2">
          <div className="col-md-3 offset-md-4">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/grzesiak_main_2.png")}
              alt=""
            />
          </div>
          <div className="col-md-3 offset-md-1">
            <h3>
              <span>5</span> Lat <br /> współpracy
            </h3>
          </div>
        </div>
        <div className="row no-gutters align-items-center row_3">
          <div className="col-md-5 text-right">
            <h3 className="on_right">
              <span>4</span> Zrealizowane <br /> projekty konferencyjne
            </h3>
          </div>
          <div className="col-md-6 offset-md-1 text-right">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/grzesiak_main_3.png")}
              alt=""
            />
          </div>
        </div>
        <div className="row no-gutters align-items-center row_4">
          <div className="col-md-6">
            <div className="scroll_wrapper left_side">
              <span />
              <div className="img_wrapper">
                <img
                  src={require("../../assets/img/portfolio/grzesiak_entire_page_2.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default MateuszGrzesiak;
